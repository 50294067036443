// Include all html tag CSS here
html,
body,
#root {
  width: 100%;
  height: 100%;
}

html,
body,
#root
.background {
  background-color: #d3d3d3;
}

body {
  word-break: break-word;
}

a {
  cursor: pointer;
  color: #027AAE;
}

.vn-tooltip {
  min-width: 300px;
}
