.print-area {
  visibility: hidden;
  display: none;
}

@media print {
  .layout, footer {
    visibility: hidden;
  }
  
  #email-helper-text {
    opacity: 0 !important;
    visibility: hidden;
    display: none;
  }
  
  #isoCode-helper-text {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  
  .print-area {
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    display: inline-block !important;
    visibility: visible !important;
    * {
      display: block !important;
      visibility: visible !important;
    }
  }
  
  .print-break-after  {
    page-break-after: always;
  }

  .print-break-inside  {
    page-break-after: auto;
  }
}